<template>
  <div>

    <div class=" box">
      <b-field label="Zoek een bestaand gebouw" grouped group-multiline>


        <b-field expanded>
          <b-input
              v-on:input="startSearching"
              expanded
              v-model="search.input"
              placeholder="e.g. AAV"
              icon="magnify"
              size="is-small"
          >
          </b-input>
          <b-checkbox-button :native-value="true" size="is-small" @input="startSearching" v-model="search.code">3LC
          </b-checkbox-button>
          <b-checkbox-button :native-value="true" size="is-small" @input="startSearching" v-model="search.name">Naam
          </b-checkbox-button>
          <b-checkbox-button :native-value="true" size="is-small" @input="startSearching" v-model="search.address">
            Adres
          </b-checkbox-button>
        </b-field>


        <p class="control" v-if="isadmin">
          <b-button @click.stop="formModal(null)"
                    class="level-item"
                    type="is-primary"
                    icon-left="plus-circle"
                    size="is-small"
          >
            Nieuw Gebouw
          </b-button>
        </p>

        <p class="control" v-if="isadmin || hasrole">
          <b-button @click.stop="download()"
                    class="level-item"
                    type="is-primary"
                    icon-left="download"
                    size="is-small"
          >
            CSV
          </b-button>
        </p>

      </b-field>

      <b-field label="Filter op labels" expanded>
        <!--           <b-checkbox-button size="is-small">Dinic</b-checkbox-button>-->
        <!--           <b-checkbox-button size="is-small">Swarco</b-checkbox-button>-->
        <!--           <b-checkbox-button size="is-small">VO_ISP</b-checkbox-button>-->

        <b-taginput
            v-model="search.labels"
            :data="filteredLabels"
            size="is-small"
            autocomplete
            expanded
            :allow-new="false"
            :open-on-focus="true"
            field="name"
            ref="taginputside"
            icon="label"
            placeholder="Selecteer labels..."
            @input="startSearching"
            @typing="getFilteredLabels">
          <template #empty>Geen resultaten voor deze zoekterm gevonden</template>
          <template #selected="props">
            <b-tag
                v-for="(tag, index) in props.tags"
                :key="index"
                :type="tag.color ? tag.color : 'is-primary'"

                :tabstop="false"

                closable
                @close="$refs.taginputside.removeTag(index, $event)">
              {{ tag.name }}
            </b-tag>
          </template>

        </b-taginput>


      </b-field>


    </div>
    <div class="box has-table">
      <b-table :data="filtered_buildings"
               style="cursor:pointer"
               ref="btable"
               hoverable
               :loading="searching"
               default-sort="code"
               default-sort-direction="asc"
               paginated
               @click="row => $router.push({name: 'buildings.map.detail', params: {code: row.code}, replace: true})"
      >

        <b-table-column field="code" label="3LC" width="40" sortable v-slot="props">
          <template v-if="props.row.geo">
            <b-tag :type="props.row.geo.properties.color === 'Blue' ? 'is-info' : 'is-primary'"><b>{{
                props.row.code
              }}</b></b-tag>
          </template>
          <template v-else>
            <b-tag type="is-primary"><b>{{
                props.row.code
              }}</b></b-tag>

          </template>
        </b-table-column>

        <b-table-column field="name" sortable label="Naam" v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="city" sortable label="Stad" v-slot="props">
          {{ props.row.city }}
        </b-table-column>

        <!--        <b-table-column field="edit" v-slot="props">-->
        <!--          <div class="buttons is-right">-->
        <!--            &lt;!&ndash;            <b-button icon-left="pencil" size="is-small" type="is-primary"&ndash;&gt;-->
        <!--            &lt;!&ndash;                      @click="formModal(props.row.code)"></b-button>&ndash;&gt;-->
        <!--            <b-button icon-left="delete" size="is-small" type="is-danger"-->
        <!--                      @click="deleteBuilding(props.row.code)"></b-button>-->
        <!--          </div>-->
        <!--        </b-table-column>-->

        <template slot="empty">
          <br>
          <p class="has-text-centered">
            <b-icon icon="office-building-outline" size="is-large"/>
          </p>
          <br>
          <p class="has-text-centered">Er zijn geen gebouwen gevonden voor bovenstaande zoekactie</p>
          <br>
        </template>

      </b-table>
    </div>
  </div>
</template>

<script>
import BuildingDetailComponent from "@/components/buildings/BuildingDetailComponent";
import debounce from 'lodash/debounce'

export default {
  name: "BuildingsOverview",
  components: {},

  created() {
    this.map = null;
  },
  data() {
    return {
      filtered_buildings: [],
      filteredLabels: this.$store.state.labels,
      searching: false,
      search: {
        code: true,
        name: true,
        address: true,
        input: "",
        labels: [],
      },
      selected: null,
    }
  },

  computed: {
    buildings() {
      return this.$store.state.buildings
    },
    isadmin() {
      return this.$store.state.user?.admin
    },
    hasrole() {
      return this.$store.state.user?.groups.includes("export")
    }
  },

  // watch: {
  //   search() {
  //     this.$store.commit('UPDATE_FILTERS', this.search)
  //   }
  // },
  mounted() {
    if (this.$store.state.current_filters) {
      //console.log("aangepast")
      this.search = this.$store.state.current_filters
    }
    this.fetchData()
  },
  methods: {
    onMapLoaded(event) {
      // in component
      this.map = event.map;
      //this.fetchData()
      // or just to store if you want have access from other components
    },

    startSearching() {
      this.searching = true
      this.filteredDataObj()
      this.$store.commit('UPDATE_FILTERS', this.search)
    },

    filteredDataObj: debounce(function () {
      if (!this.search.input) this.filtered_buildings = this.buildings
      this.filtered_buildings = this.buildings.filter((option) => {
        return (option.code
                .toLowerCase()
                .indexOf(this.search.input.toLowerCase()) >= 0 && this.search.code) ||
            (option.name
                .toLowerCase()
                .indexOf(this.search.input.toLowerCase()) >= 0 && this.search.name) ||
            (option.address
                .toLowerCase()
                .indexOf(this.search.input.toLowerCase()) >= 0 && this.search.address) ||
            (option.city
                .toLowerCase()
                .indexOf(this.search.input.toLowerCase()) >= 0 && this.search.address)

      })
      if (this.search.labels.length > 0) {
        // Filter on selected label id's
        this.filtered_buildings = this.filtered_buildings.filter((option) => {
          return this.search.labels.map(i => i.id).every(val =>
              option.labels.map(i => i.id).includes(val))
        })
      }
      this.searching = false

      this.$store.commit("UPDATE_FILTERED_BUILDINGS", this.filtered_buildings)

    }, 150),


    fetchData() {
      if(this.buildings.length === 0){
        this.searching = true
      } else {
          this.searching = true
          this.filteredDataObj()
          this.$store.commit("UPDATE_FILTERED_BUILDINGS", this.filtered_buildings)
          this.$store.dispatch("setCurrentBuilding", null)
      }
      // Do async update anyway
      this.$store.dispatch("fetchAllBuildings").then(response => {
        this.buildings = response.data
        this.filteredDataObj()
        this.$store.commit("UPDATE_FILTERED_BUILDINGS", this.filtered_buildings)
        this.$store.dispatch("setCurrentBuilding", null)
      })

      this.$store.dispatch('setTitle', {
        back: true,
        stack: [
          {
            message: "Gebouwen",
          }
        ]
      })
    },

    formModal(code) {
      this.$buefy.modal.open({
        parent: this,
        component: BuildingDetailComponent,
        props: {
          code: code
        },
        hasModalCard: false,
        trapFocus: true
      })
    },


    deleteBuilding(code) {
      this.$store.dispatch("deleteBuilding", code).then(() => {
        this.$buefy.toast.open({
          message: 'Gebouw verwijderd.',
          type: 'is-success',
          position: 'is-top'
        })
        this.fetchData()
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Gebouw kon niet verwijderd worden.',
          type: 'is-danger',
          position: 'is-top'
        })
      })


    },

    drawBuildings() {

      this.geoJsonSource.data.features = this.buildings.map(i => i["geo"]).filter(i => i)


      console.log(this.geoJsonSource)


    },
    getFilteredLabels(text) {
      this.filteredLabels = this.$store.state.labels.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },

    download() {
       this.$store.dispatch("downloadCsv", this.filtered_buildings.map(b => b.code)).then(() => {
        this.$buefy.toast.open({
          message: 'CSV Gedownload',
          type: 'is-success',
          position: 'is-top'
        })
        this.fetchData()
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'CSV kon niet gedownload worden.',
          type: 'is-danger',
          position: 'is-top'
        })
      })


    }

  }
}
</script>

<style scoped>

.box {
  transition: box-shadow .3s;

}

.box:hover {
  box-shadow: 0 0 12px rgba(33, 33, 33, .2);
}

</style>