<template>

  <card-component :has-title-slot="true"
                  :is-scrollable="true">
    <header v-if="!creating" slot="title" class="level is-mobile">
      <div class="level-left">
        <a class="level-item" @click="$router.go(-1)">
          <b-icon type="is-grey-darker" icon="arrow-left" class="ml-4"></b-icon>
        </a>
        <template v-if="building.geo">
          <b-tag class="level-item" :type="building.geo.properties.color === 'Blue' ? 'is-info' : 'is-primary'">
            <b>{{ building.code }}</b>
          </b-tag>
        </template>
        <template v-else>
          <b-tag class="level-item" type="is-primary">
            <b>{{ building.code }}</b>
          </b-tag>
        </template>
        <p class="level-item title is-6 pl-0">
          {{ building.name }}
        </p>
      </div>
    </header>
    <header v-else slot="title" class="level is-mobile">
      <div class="level-left">
        <b-tag class="level-item" type="is-primary"><b>{{ building.code }}</b></b-tag>
        <p class="level-item title is-6 pl-0">
          Voeg nieuw gebouw toe
        </p>
      </div>
    </header>


    <form class="card-content" @submit.prevent="submit" id="create_form">

      <b-notification
          type="is-danger"
          v-model="hasError"
          aria-close-label="Close notification">
        {{ errorMessage }}
      </b-notification>


      <div class="level">
        <div class="level-left">
          <p class="level-item title is-4">Details Gebouw</p>
        </div>

        <div v-if="isadmin" class="level-right">
          <b-button @click="startRelocating" size="is-small" class="level-item" icon-left="map-marker-radius"
                    type="is-primary is-light">
            {{ this.relocating ? "Klik op kaart op locatie te wijzigen..." : "Verander Locatie" }}
          </b-button>

          <b-button size="is-small" class="level-item" icon-left="content-save" native-type="submit" type="is-primary">
            {{ this.creating ? "Voeg toe" : "Bewaar" }}
          </b-button>
        </div>
      </div>

      <b-field grouped group-multiline>
        <b-field expanded label="Naam" class="control">
          <b-input v-bind:disabled="!isadmin" icon="office-building" v-model="building.name" placeholder="Gebouw naam"
                   name="name" required/>
        </b-field>

        <b-field label="3LC" class="control">
          <b-input icon="key" v-bind:disabled="!this.creating" v-model="building.code" placeholder="bv. 'ALZ'"
                   name="code"
                   required/>
        </b-field>

      </b-field>

      <b-field grouped group-multiline>

        <b-field label="Straatnaam" expanded class="control">
          <b-input v-model="building.address" v-bind:disabled="!isadmin" placeholder="Straatnaam" name="address"
                   required/>
        </b-field>
        <b-field label="Huisnummer" class="control">
          <b-input v-model="building.number" v-bind:disabled="!isadmin" placeholder="Nummer" name="number"/>
        </b-field>
      </b-field>
      <b-field grouped group-multiline>
        <b-field label="Postcode" class="control">
          <b-input v-model="building.postal" v-bind:disabled="!isadmin" placeholder="Postcode" name="postal" required/>
        </b-field>
        <b-field expanded label="Gemeente" class="control">
          <b-input v-model="building.city" v-bind:disabled="!isadmin" placeholder="Gemeente" name="city" required/>
        </b-field>
        <b-field label="Provincie" class="control">
          <b-select v-model="building.province" v-bind:disabled="!isadmin" placeholder="Provincie">
            <option value="Antwerpen">Antwerpen</option>
            <option value="Brussel">Brussel</option>
            <option value="Limburg">Limburg</option>
            <option value="Oost-Vlaanderen">Oost-Vlaanderen</option>
            <option value="Vlaams Brabant">Vlaams Brabant</option>
            <option value="West-Vlaanderen">West-Vlaanderen</option>
            <option value="Vlaams Brabant">Henegouwen</option>
            <option value="Luik">Luik</option>
            <option value="Luxemburg">Luxemburg</option>
            <option value="Namen">Namen</option>
            <option value="Waals Brabant">Waals</option>
          </b-select>
        </b-field>
      </b-field>

      <template v-if="!creating">

        <b-field label="Labels" grouped>
          <b-taginput
              v-model="building.labels"
              :data="filteredLabels"
              v-bind:disabled="!isadmin"
              autocomplete
              :allow-new="false"
              expanded
              :open-on-focus="true"
              field="name"
              ref="taginput"
              icon="label"
              placeholder="Voeg een nieuw label toe"
              @typing="getFilteredLabels"
              @select-footer="showAddLabel"
              :selectable-footer="true">
            <template #footer>
              <a><span> Voeg nieuw label toe...</span></a>
            </template>
            <template #empty>Geen resultaten voor deze zoekterm gevonden</template>
            <template #selected="props">
              <b-tag
                  v-for="(tag, index) in props.tags"
                  :key="index"
                  :type="tag.color ? tag.color : 'is-primary'"
                  :tabstop="false"
                  :closable="isadmin"
                  @close="$refs.taginput.removeTag(index, $event)">
                {{ tag.name }}
              </b-tag>
            </template>

          </b-taginput>

          <p class="control">
            <b-button @click="showAddLabel" class="button is-primary">+</b-button>
          </p>

        </b-field>

        <hr>

        <p class="title is-4">Contactgegevens</p>


        <div class="columns is-multiline ">
          <div v-for="(contact, index) in building.contacts" :key="index"
               class="column  is-12-tablet is-12-desktop is-12">
            <div class="card" style="cursor: pointer" @click="showContactPopup(index)">
              <div class="card-content">
                <div class=" media">
                  <div class="media-content">

                    <span class="icon-text">
                      <span class="icon">
                        <b-icon icon="account"></b-icon>
                      </span>
                      <span class="has-text-weight-bold">
                        {{ contact.name }}
                      </span>
                    </span>

                    <p class="pt-2">
                      <span class="icon-text">
                        <span class="icon mr-2">
                          <b-icon icon="clipboard"></b-icon>
                        </span>
                        <span>
                        {{ contact.fun }}
                        </span>
                      </span>
                    </p>

                    <p class="pt-2">
                      <span class="icon-text">
                        <span class="icon mr-2">
                          <b-icon icon="phone"></b-icon>
                        </span>
                        <span class="has-text-weight-bold">
                        {{ contact.phone }}
                        </span>
                      </span>
                    </p>

                    <p class="pt-2">
                      <span class="icon-text">
                        <span class="icon mr-2">
                          <b-icon icon="email"></b-icon>
                        </span>
                        <span class="has-text-weight-bold">
                          <a class="has-text-primary-dark" :href="'mailto:'+ contact.email">{{ contact.email }}</a>
                        </span>
                      </span>
                    </p>
                  </div>

                  <div v-if="isadmin" class="media-right">
                    <button @click.prevent.stop="promptDeleteContact(index)" class="delete"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="isadmin" class="column is-6">
            <div class="card" style="cursor: pointer" @click="showContactPopup">
              <div class="card-content">
                <span class="icon-text">
                  <span class="icon">
                    <b-icon icon="account-plus"></b-icon>
                  </span>
                  <span class="has-text-weight-bold">Voeg toe</span>
                </span>

              </div>
            </div>
          </div>
        </div>


        <b-field label="Toegangsprocedure"
                 message="Beschrijf hier welke gegevens er nodig zijn om toegang te krijgen tot het gebouw.">
          <b-input v-model="building.contact_access_procedure" :disabled="!isadmin" type="textarea"></b-input>
        </b-field>

        <hr>
        <b-field label="Extra gegevens en opmerkingen" message="Indien nodig">
          <b-input v-model="building.notes" :disabled="!isadmin" type="textarea"></b-input>
        </b-field>


        <!--      <hr>-->
        <!--      <b-field label="Beschikbare Vlans" horizontal>-->
        <!--        <div class="media-list">-->
        <!--          <div class="media" v-for="vlan in building.vlans" :key="vlan.id">-->
        <!--            <div class="media-left">-->
        <!--              <b-icon icon="router-network">-->
        <!--              </b-icon>-->
        <!--              <b><p>{{ vlan.code }}</p></b>-->
        <!--            </div>-->
        <!--            <div class="media-content">-->
        <!--              {{ vlan.name }}-->
        <!--              <br>-->
        <!--              <small>-->
        <!--                {{ vlan.description }}-->
        <!--              </small>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </b-field>-->

        <hr>
        <p class="title is-4">Bijlagen</p>

        <div class="media-list">
          <article v-for="att in building.attachments" :key="att.uuid" class="media my-2">
            <figure class="media-left">
              <b-button @click="downloadAttachment(att.uuid, att.name)" size="is-small" icon-left="download"></b-button>
            </figure>
            <div class="media-content ^">
              <div class="content">
                <p>
                  {{ att.name }}
                </p>
              </div>
            </div>
            <div v-if="isadmin" class="media-right">
              <button @click="deleteAttachment(att.uuid)" class="delete"></button>
            </div>
          </article>
          <template v-if="building.attachments && building.attachments.length === 0">
            <small>Er zijn nog geen bijlagen geüpload.</small>
          </template>
        </div>

        <br>


        <file-pond
            v-if="isadmin"
            name="file"
            ref="pond"
            label-idle="Sleep bijlagen hierheen om te uploaden..."
            credits="false"
            v-bind:allow-multiple="true"
            :server="serverUrl"
            v-bind:files="attachments"
            @addfilestart="loading = true"
            @processfile="addAttachment"
            @processfiles="loading = false"
        />
      </template>

      <template v-else>
        <br>
        <b-notification>Het toevoegen van contactgevens en bijlagen kan in de volgende stap.</b-notification>
      </template>
      <br>

      <b-field horizontal v-if="isadmin">
        <b-field grouped position="is-right">
          <div v-if="!this.creating" class="control">
            <b-button size="is-small" icon-left="delete" @click="promptDelete" type="is-danger">Verwijder gebouw
            </b-button>
          </div>
          <div class="control">
            <b-button size="is-small" icon-left="content-save" native-type="submit" type="is-primary">
              {{ this.creating ? "Voeg toe" : "Bewaar" }}
            </b-button>
          </div>
        </b-field>

      </b-field>

    </form>
  </card-component>


</template>

<script>

import CardComponent from "@/components/CardComponent";

// Import Vue FilePond
import vueFilePond from "vue-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import ContactFormComponent from "@/components/buildings/ContactFormComponent";

const FilePond = vueFilePond(

);
export default {
  name: "BuildingDetailComponent",
  components: {CardComponent, FilePond},
  props: {
    orig_code: {
      default: null
    },
  },
  data() {
    return {
      building: {},
      content: {},
      attachments: [],
      filteredLabels: [],
      read: false,
      code: null,
      hasError: false,
      errorMessage: "",
      loading: false,
      relocating: false,

    }
  },

  computed: {
    creating() {
      return this.code === undefined || null
    },
    isadmin() {
      return this.$store.state.user?.admin
    },
    serverUrl() {
      return {
        url: (process.env.VUE_APP_API_URL ?? 'https://api.sites.netwerkdiensten.be/') + 'buildings/' + this.code,
        process: {
          url: '/upload/',
          method: 'POST',
          withCredentials: true,
          headers: {"Authorization": "Bearer " + localStorage.getItem("jwt"), "Accept": "application/json"},
          timeout: 7000,
          onload: null,
          onerror: null,
          ondata: null,
        }
      }
    },
    relocated_location() {
      return this.$store.state.relocated_location
    }

  },

  watch: {
    '$route'() {
      this.code = this.$route.params.code // TODO Omweggeske
      if (!this.code) return
      this.$store.dispatch('fetchBuildingDetails', this.code)
          .then(response => {
            this.building = JSON.parse(JSON.stringify(response.data))
            this.$store.dispatch("setCurrentBuilding", Object.create(this.building))
          })
    },
    relocated_location(newLoc) {
      // Our fancy notification (2).
      if (newLoc) {
        this.building.logitude = newLoc.lng.toFixed(6)
        this.building.latitude = newLoc.lat.toFixed(6)
        // eslint-disable-next-line no-unused-vars
        this.submit().then(res => {
          this.relocating = false
          this.$store.dispatch("setRelocatedLocation", null)
          this.$store.dispatch("startRelocating", false)
        })

      }
    }
  },

  mounted() {
    // Fetch building data
    this.code = this.$route.params.code // TODO Omweggeske
    if (!this.code) return
    this.fetchData()
    this.filteredLabels = this.$store.state.labels

  },

  methods: {

    fetchData() {
      this.$store.dispatch('fetchBuildingDetails', this.code)
          .then(response => {
            this.building = JSON.parse(JSON.stringify(response.data))
            if (!this.building?.contacts) {
              this.building.contacts = []
            }
            this.$store.dispatch("setCurrentBuilding", Object.create(this.building))
          })
    },
    getFilteredLabels(text) {
      this.filteredLabels = this.$store.state.labels.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },

    showAddLabel() {
      this.$buefy.dialog.prompt({
        message: `Label`,
        inputAttrs: {
          placeholder: 'e.g. Agentschap X',
          maxlength: 80,
          value: this.name
        },
        confirmText: 'Voeg toe',
        onConfirm: (value) => {
          this.$store.dispatch('createLabel', {'name': value}).then(result => {
            this.$buefy.toast.open({
              message: 'Label toegevoegd',
              type: 'is-success'
            })
            this.building.labels.push(result.data)
          })
        }
      })
    },

    showContactPopup(index = null) {
      this.$buefy.modal.open({
        parent: this,
        component: ContactFormComponent,
        props: {
          orig_contact: this.building.contacts[index]
        },
        hasModalCard: true,
        trapFocus: true,
        events: {
          'updatecontact': e => {
            this.building.contacts[index] = e
            this.submit()
          },
          'newcontact': e => {
            this.building.contacts.push(e)
            this.submit()
          }
        }
      })

    },

    async submit() {
      // Add new building
      if (this.creating) {
        await this.$store.dispatch('createBuilding', this.building)
            .then(response => {
              this.$buefy.toast.open({
                message: 'Building created',
                type: 'is-success'
              })
              //this.$emit("update")
              this.$parent.close()
              this.$router.push({name: 'buildings.map.detail', params: {code: response.data.code}, replace: true})

            }).catch(err => {
              document.getElementById('create_form').scrollIntoView();
              this.errorMessage = err.response.data
              this.hasError = true
            })
      }
      // Update existing building
      else {
        this.building.labels = this.building.labels.map(e => e.id)
        await this.$store.dispatch('updateBuilding', {code: this.code, data: this.building})
            .then(response => {
              this.building = JSON.parse(JSON.stringify(response.data))
              console.log(this.building)
              this.$store.dispatch("updateBuildingLocationInFilteredBuildings", this.building)
              this.$store.dispatch("setCurrentBuilding", Object.create(this.building))
              this.$buefy.toast.open({
                message: 'Wijzigingen bewaard',
                type: 'is-success'
              })

            }).catch(err => {
              document.getElementById('create_form').scrollIntoView();
              this.errorMessage = err.response.data
              this.hasError = true

            })
      }

    },

    promptDelete() {
      this.$buefy.dialog.confirm({
        title: 'Gebouw verwijderen',
        message: 'Weet u zeker dat u dit gebouw wilt <b>verwijderen?</b> Deze actie kan niet ongedaan gemaakt worden.',
        confirmText: 'Verwijder gebouw',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteBuilding()
      })
    },
    deleteBuilding() {
      this.$store.dispatch("deleteBuilding", this.building.code).then(() => {
        this.$buefy.toast.open({
          message: 'Gebouw verwijderd.',
          type: 'is-success',
          position: 'is-top'
        })
        this.$router.push({name: 'buildings.overview.side'})
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Gebouw kon niet verwijderd worden.',
          type: 'is-danger',
          position: 'is-top'
        })
      })
    },

    promptDeleteContact(index) {
      this.$buefy.dialog.confirm({
        title: 'Contact verwijderen',
        message: 'Weet u zeker dat u deze contactpersoon wilt <b>verwijderen?</b> Deze actie kan niet ongedaan gemaakt worden.',
        confirmText: 'Verwijder',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteContact(index)
      })
    },

    deleteContact(index) {
      this.building.contacts.splice(index, 1)
      this.submit()
    },

    addAttachment() {
      let attachments = this.$refs.pond.getFiles().map(e => e.serverId)
      this.uploading = true;
      this.$store.dispatch("deplo/createComment", {
        body: this.comment_text,
        commentable_id: this.id,
        commentable_type: this.type,
        attachment: attachments ? attachments[0] : null // TODO Currently only one photo max
      }).then(resp => {
        console.log(resp)
        this.uploading = false
        this.comment_text = null
        this.$refs.pond.removeFile(attachments)
        this.$refs.commentContent.scrollTop = this.$refs.commentContent.scrollHeight;
        console.log(this.comments)
      }).catch(err => {
        this.uploading = false
        console.log(err)
        this.$buefy.toast.open({
          message: `Something went wrong.`,
          type: 'is-danger'
        })
      })
    },

    downloadAttachment(uuid, filename) {
      this.$store.dispatch("downloadAttachment", uuid).then((response) => {

        const link = document.createElement("a");
        link.href = response.data.url;
        link.download = filename;
        link.target = "_blank";
        link.click();

        this.$buefy.toast.open({
          message: 'Bijlage gedownload.',
          type: 'is-success',
          position: 'is-top'
        })
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Bijlage kon niet gedownload worden.',
          type: 'is-danger',
          position: 'is-top'
        })
      })
    },

    deleteAttachment(uuid) {
      this.$store.dispatch("deleteAttachment", uuid).then(() => {
        this.$buefy.toast.open({
          message: 'Bijlage verwijderd.',
          type: 'is-success',
          position: 'is-top'
        })
        this.fetchData()
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Bijlage kon niet verwijderd worden.',
          type: 'is-danger',
          position: 'is-top'
        })
      })


    },

    startRelocating() {
      this.relocating = true
      this.$store.dispatch("startRelocating", true)

    }

  }

}
</script>

<style scoped lang="scss">


</style>