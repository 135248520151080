<template>
  <card-component :title="this.creating ? 'Nieuw contact': 'Wijzig contact'"
                  :icon="this.creating ? 'plus-circle' : 'pencil'"
                  style="min-width: 60vw"
                  :is-scrollable="true">

    <form @submit.prevent="submit">
      <b-field grouped group-multiline message="U kan nog extra gegevens toevoegen in de velden hieronder.">
        <b-field label="Naam" expanded class="control">
          <b-input v-model="contact.name" icon="account"
                   placeholder="Contactpersoon.."></b-input>
        </b-field>
        <b-field label="Functie" expanded class="control">
          <b-input v-model="contact.fun" icon="account-tie"
                   placeholder="Verantwoordelijke"></b-input>
        </b-field>
      </b-field>
      <b-field grouped group-multiline>
        <b-field label="Telefoonnr" expanded class="control">
          <b-input v-model="contact.phone" icon="phone" placeholder="+324..."></b-input>
        </b-field>
        <b-field label="Emailadres" expanded class="control">
          <b-input v-model="contact.email"  icon="email" type="email"
                   placeholder="bv. jef.dierckx@vlaanderen.be"></b-input>
        </b-field>
      </b-field>

      <hr>

      <b-field horizontal>
        <b-field grouped position="is-right">
          <div class="control">
            <b-button type="is-primary is-outlined" @click="this.$parent.close">Annuleer</b-button>
          </div>
          <div class="control">
            <b-button native-type="submit" type="is-primary">{{
                this.creating ? "Voeg toe" : "Bewaar"
              }}
            </b-button>
          </div>
        </b-field>

      </b-field>

    </form>

  </card-component>
</template>

<script>

import CardComponent from "@/components/CardComponent";

export default {
  name: "ContactFormComponent",
  components: {CardComponent},
  props: {
    orig_contact: {
      default: null
    },
  },
  data() {
    return {
      contact: {},
    }
  },

  computed: {
    creating() {
      return this.orig_contact === null
    }

  },
  mounted() {
    if (this.creating) {
      this.contact = {
        name: "",
        fun: "",
        email: "",
        phone: ""

      }
    } else {
      // Fetch contact data
      this.contact = this.orig_contact
    }
  },
  methods: {
    submit() {
      if (this.deleting) {
        this.$emit("deletecontact", this.contact)
        this.$parent.close()
      }
      // Add new contact
      if (this.creating) {
        this.$emit("newcontact", this.contact)
        this.$parent.close()
      } else {
        this.$emit("updatecontact", this.contact)
        this.$parent.close()
      }
    }
  }
}
</script>

<style scoped>

</style>